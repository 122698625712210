import { reducer } from './js/app/reducer';
import './SchemeColor';

window.kinosite = {
	reducer,
	additionalModules: {
		gosuslugiOnAfisha: {
			getScript: () => import('./js/templates/gosuslugi/script.html'),
			getTemplate: () => import("./js/templates/gosuslugi/template.html"),
			gosuslugiID: 355443
		},
		saleRulesPage: { getData: () => import("./js/templates/saleRules.html") },
		detailsPage: { getData: () => import("./js/templates/details.html") },
	},
};
